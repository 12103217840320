.viewBox.TempPower {
    justify-content: space-evenly;
    align-items: center;
    background: #4092f5;
    border-radius: 10px;
    height: 464px;
}

#PowerWhiteIcon {
    margin-bottom: 8px;
}

.TempPowerTemplate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TempPowerNumTemplate {
    font-style: normal;
    font-weight: bold;
    font-size: 2.8rem;
    text-align: center;
    color: #ffffff;
    margin-top: 40px;
    margin-bottom: 13px;
}

.TempPowerTextTemplate {
    font-style: normal;
    font-weight: normal;
    font-size: 1.1875rem;
    text-align: center;
    color: #ffffff;
}

.WhiteTemplate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff;
    height: 220px;
    border-radius: 10px;
}

.TemplateIconText {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 33px;
    margin-left: 42px;
    width: 230px;
}

.TemplateText {
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: left;
    color: #4092f5;
    margin-left: 33px;
    margin-top: 15px;
}

.TemplateSwitch {
    display: flex;
    justify-content: flex-end;
    margin-right: 23px;
    margin-top: 74px;
}

/* .switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 30px;
} */

/* .switch input {
    opacity: 0;
    width: 0;
    height: 0;
} */

/* .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #d5e4f7;
    -webkit-transition: 0.4s;
    transition: 0.4s;
} */

/* .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background: #a1b0cb;
    -webkit-transition: 0.4s;
    transition: 0.4s;
} */

/* input:checked + .slider {
    background-color: #4c6fff;
}

input:checked + .slider:before {
    background-color: #ffffff;
}

input:checked + .slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
} */

#SetTempPlusMinus {
    display: flex;
    margin-left: 108px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: white;
    border: 1px solid #e2e2e2;
    border-radius: 9px;
    box-sizing: border-box;
    margin-top: 20px;
    height: 64px;
    width: 122px;
}

#PlusMinusText {
    font-size: 1.5625rem;
    font-weight: 800;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

#TempPlusMinus {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-width: 42px;
    height: 62px;
    background-color: #f7fafc;
    border-radius: 8px;
}

#Plus {
    background-color: #f7fafc;
    color: #4092f5;
    font-size: 1.5625rem;
    border: 0px;
    border-radius: 8px;
    height: 31px;
}

#Minus {
    background-color: #f7fafc;
    color: #4092f5;
    font-size: 1.75rem;
    border: 0px;
    border-radius: 8px;
    height: 31px;
}

#AutoButtonSwitch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 24px;
    margin-top: 68px;
}

#AutoButton {
    background: #878a94;
    font-size: 0.75rem;
    border-radius: 100px;
    border: none;
    transition-duration: 0.4s;
    width: 105px;
    color: white;
    font-weight: bold;
    height: 36px;
    text-align: center;
    cursor: not-allowed;
}

/* #AutoButton:hover {
    background-color: black;
    color: white;
} */

#AutoSwitch {
    margin-right: 23px;
}
