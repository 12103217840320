.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin: 0 15px; */
}

.column {
    flex-direction: column;
}

.nowrap {
    flex-wrap: nowrap;
}

.col {
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.col.col-1 {
    flex-grow: 1;
    min-width: 8.33%;
}

.col.col-2 {
    flex-grow: 2;
    min-width: 16.66%;
}

.col.col-3 {
    flex-grow: 3;
    min-width: 25%;
}

.col.col-4 {
    flex-grow: 4;
    min-width: 33.33%;
}

.col.col-5 {
    flex-grow: 5;
    min-width: 41.66%;
}

.col.col-6 {
    flex-grow: 6;
    min-width: 50%;
}

.col.col-7 {
    flex-grow: 7;
    min-width: 58.33%;
}

.col.col-8 {
    flex-grow: 8;
    min-width: 66.66%;
}

.col.col-9 {
    flex-grow: 9;
    min-width: 75%;
}

.col.col-10 {
    flex-grow: 10;
    min-width: 83.33%;
}

.col.col-11 {
    flex-grow: 11;
    min-width: 91.66%;
}

.col.col-12 {
    flex-grow: 12;
    min-width: 100%;
}

.viewBox {
    display: flex;
    width: 100%;
    border-radius: 10px;
    margin: 10px 9px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

/* .viewBox.header {
    flex-wrap: nowrap;
    height: 52px;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
} */

/* .viewBox.header h1 {
    margin: 0;
    color: #3754db;
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
} */

/* .viewBox.header img {
    width: 25px;
    height: 25px;
} */
