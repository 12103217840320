@import "./normalize.scss";

/* inter-regular - latin_cyrillic */
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: local(""),
        url("./assets/fonts/inter-v7-latin_cyrillic-regular.woff2")
            format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("./assets/fonts/inter-v7-latin_cyrillic-regular.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-600 - latin_cyrillic */
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: local(""),
        url("./assets/fonts/inter-v7-latin_cyrillic-600.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("./assets/fonts/inter-v7-latin_cyrillic-600.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-800 - latin_cyrillic */
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    src: local(""),
        url("./assets/fonts/inter-v7-latin_cyrillic-800.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url("./assets/fonts/inter-v7-latin_cyrillic-800.woff")
            format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Mui brakpoints
  xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px
*/

body {
    font-family: "Inter", sans-serif;
    background-color: #f4f4f4;
    /* min-width: 800px; */
    min-height: 100vh;
    width: 100vw;
    -webkit-overflow-scrolling: touch;
}

#root {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0 15px;
}

button,
textarea,
input,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Edge, Opera and Firefox */
}

/* Added to reset height on mobile */
@media screen and (orientation: landscape) {
    body {
        height: 100vh;
        min-height: 100vh;
    }
}

/* Added to reset height on mobile */
@media screen and (orientation: portrait) {
    body {
        height: 100vh;
        min-height: 100vh;
    }
}
