.viewBox.CurrTemp {
    justify-content: center;
    align-items: center;
    background: #4092f5;
    height: 220px;
}

#CurrTempNumText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 55px;
}

#VentCurrTempNum {
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    color: white;
}

#VentCurrTempText {
    font-size: 1.09375rem;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    color: white;
}

.viewBox.SetTemp {
    background: white;
    flex-direction: column;
    border-radius: 10px;
    height: 220px;
}

.viewBox.SetSpeed {
    background: white;
    flex-direction: column;
    border-radius: 10px;
    height: 220px;
}

#TempIconText {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 36px;
    margin-left: 50px;
}

#SpeedIconText {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 36px;
    margin-left: 50px;
}

#TempTextTemplate {
    font-size: 1.09375rem;
    font-style: normal;
    font-weight: 800;
    text-align: left;
    color: #4092f5;
    margin-left: 33px;
    margin-top: 6px;
}

#SpeedTextTemplate {
    font-size: 1.09375rem;
    font-style: normal;
    font-weight: 800;
    text-align: left;
    color: #4092f5;
    margin-left: 33px;
    margin-top: 6px;
}

.SetPlusMinus {
    display: flex;
    margin-left: 108px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: white;
    border: 1px solid #e2e2e2;
    border-radius: 9px;
    box-sizing: border-box;
    margin-top: 20px;
    height: 64px;
    width: 122px;
}

.PlusMinusText {
    font-size: 1.5625rem;
    font-weight: 800;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.PlusMinus {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-width: 42px;
    height: 62px;
    background-color: #f7fafc;
    border-radius: 8px;
}

.Plus {
    background-color: #f7fafc;
    color: #4092f5;
    font-size: 1.5625rem;
    border: 0px;
    border-radius: 8px;
    height: 31px;
}

.Minus {
    background-color: #f7fafc;
    color: #4092f5;
    font-size: 1.75rem;
    border: 0px;
    border-radius: 8px;
    height: 31px;
}

.Plus:hover {
    cursor: pointer;
}

.Minus:hover {
    cursor: pointer;
}
