.viewBox.ventilation {
    justify-content: space-between;
    /* height: 116px; */
    background: #4092f5;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.viewBox.automation {
    height: 116px;
    justify-content: space-between;
    background: #c8cace;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.viewBox.roomItem {
    flex-direction: column;
    height: 204px;
    background: white;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.iconTextState {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    margin-left: 25px;
}

.iconText {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.iconText img {
    width: 40px;
    height: 40px;
}

.iconText span {
    font-style: normal;
    font-weight: 800;
    font-size: 1.1875rem;
    text-align: left;
    color: white;
    margin-left: 20px;
}

.state img {
    width: 16px;
    height: 16px;
}

.state span {
    color: #4092f5;
    font-size: 1rem;
    font-weight: bold;
    font-style: normal;
    margin-left: 12px;
}

.state span.blue {
    color: #3754db;
}

.inactive .state span {
    color: #878a94;
}

.state.num {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.state.num img {
    width: 30px;
    height: 30px;
}

.state.num span {
    font-size: 1.1875rem;
    margin-left: 2px;
}

.btn.settings {
    align-self: baseline;
    background: #3754db;
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    border-radius: 100px;
    border: none;
    transition-duration: 0.4s;
    width: 105px;
    height: 36px;
    color: white;
    margin-right: 20px;
}

.btn.settings.top {
    margin-top: 16px;
}

.btn.settings:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

.inactive > .btn.settings {
    background: #878a94;
    cursor: not-allowed;
}

#ventTempSpeed {
    display: flex;
    align-items: center;
}

.ventParams {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 88px;
    border-radius: 10px;
    margin: 14px 6px;
    background: #539df6;
    align-items: center;
}

.ventParamItem {
    display: flex;
    flex-direction: column;
}

.ventValue {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1.0625rem;
    text-align: center;
}

.ventText {
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8125rem;
    text-align: center;
    margin-top: 5px;
    margin-left: 23.5px;
    margin-right: 28px;
}

.vLine {
    background-color: #ffffff;
    width: 0.8px;
    height: 70%;
}

.hLine {
    background-color: #f4f4f4;
    height: 0.8px;
    width: 95%;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
}

.roomHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-left: 24px;
}

.roomName {
    color: black;
    font-style: normal;
    font-weight: 800;
    font-size: 1.1875rem;
    width: 200px;
}

.roomStates {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 26px;
    margin-right: 23px;
}

.rosetteLight {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.tempPower {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* width: 120px */
}
